<template>
	<section class="core-links">
		<LinkWrap
			v-bind="link"
			v-for="(link, idx) in coreLinks"
			:key="idx"
			class="chip"
			custom-type="div"
		>
			<TnChip :text="link.text" />
		</LinkWrap>
	</section>
</template>

<script>
import LinkViewModel from "../../../../helpers/ViewModels/LinkViewModel.js";

export default defineNuxtComponent({
	name: "CoreLinks",

	props: {
		links: {
			type: Array,
		},
	},

	computed: {
		coreLinks() {
			return this.links && this.links.map(LinkViewModel);
		},
	},
});
</script>

<style lang="scss" scoped>
.core-links {
	margin: -$spacing-s;
}

.chip {
	margin: $spacing-s;
}
</style>
